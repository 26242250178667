<script>
import { Line } from "vue-chartjs";
import chartjsPluginAnnotation from "chartjs-plugin-annotation";

export default {
  extends: Line,
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },

  methods: {
    renderLineChart() {
      this.renderChart(this.chartData, this.options);
    },
  },

  watch: {
    chartData() {
      this.renderLineChart();
    },
  },

  mounted() {
    this.addPlugin(chartjsPluginAnnotation);
    this.renderLineChart();
  },
};
</script>
