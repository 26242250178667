<template>
  <div>
    <LoadingSpinner :isLoading="isLoading" />
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-left flex flex-wrap">
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="reportType == 0 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="reportType = 0">Logg</div>
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="reportType == 1 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="reportType = 1">Batteri</div>
          <div
            class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer"
            v-bind:class="reportType == 2 ? 'bg-primary-500' : 'hover:bg-primary-400'"
            @click="
              reportType = 2;
              focusCurrentPosition();
            "
          >
            Map
          </div>
        </div>
        <div class="float-right flex flex-wrap">
          <div v-if="lastSeen" class="text-sm px-3 mt-1">
            <span class="font-medium">{{ lastSeen.value }}</span>
          </div>
          <div class="datepicker-wrapper mx-2 mt-2 lg:mt-0">
            <date-picker :lang="date_picker_lang" :key="date_picker_langKey" v-model="date_range" type="date" range :editable="false" :clearable="false" @change="getGPSReport()" :disabled-date="disableDate">
              <template v-slot:footer="{ emit }">
                <div class="text-left hidden lg:block">
                  <button class="datepicker-action-btn mr-1" @click="selectToday(emit)">Idag</button>
                  <button class="datepicker-action-btn mr-1" @click="selectCurrentWeek(emit)">Denna vecka</button>
                  <button class="datepicker-action-btn mr-1" @click="selectLastWeek(emit)">Förra veckan</button>
                  <button class="datepicker-action-btn mr-1" @click="selectLast2Week(emit)">Senaste 2 veckan</button>
                </div>
              </template>
            </date-picker>
          </div>
          <button class="btn-blue-outline mx-2 mt-3 sm:mt-0" @click.prevent="exportData()">
            Export
            <BaseIcon icon="file-excel" class="ml-1" />
          </button>
        </div>
      </div>
      <div class="w-full mt-6">
        <div v-if="reportType == 0">
          <vue-good-table
            styleClass="vgt-table striped bordered condensed whitespace-nowrap"
            row-style-class="text-sm"
            :columns="columns"
            :rows="reports"
            :search-options="{
              enabled: true,
              placeholder: 'Sök',
            }"
            :pagination-options="{
              enabled: true,
              perPage: 200,
              perPageDropdown: [200, 400, 600, 1000],
              dropdownAllowAll: false,
            }"
          >
          </vue-good-table>
        </div>
        <div class="flex flex-col" v-if="reportType == 1">
          <div class="w-full">
            <LineChart v-if="voltageData != null" :chartData="voltageData" :options="voltageOptions" />
          </div>
          <!-- <div class="w-full flex flex-wrap border border-secondary-300 mt-5 px-4 pt-2 pb-4">
            <div class="w-1/2 mt-2">Total charging duration: </div>
            <div class="w-1/2 mt-2">Battery consumed since last charge: </div>
            <div class="w-1/2 mt-2">Maximum voltage: </div>
            <div class="w-1/2 mt-2">Minimum voltage: </div>
          </div> -->
        </div>
        <div class="flex flex-col" v-if="reportType == 2">
          <div class="w-full flex flex-wrap bg-gray-50 px-4">
            <div class="w-full lg:w-1/12">
              <button class="btn-blue-outline px-2 py-1 mt-2" @click="focusCurrentPosition()">
                <BaseIcon icon="crosshairs" />
              </button>
            </div>
            <div class="w-full lg:w-2/12">
              <label class="inline-flex items-center mt-3">
                <input v-model="showPoints" :value="showPoints" type="checkbox" class="form-checkbox h-4 w-4" @change="showTrackedPoints()" />
                <span class="ml-2 text-sm font-sans font-semibold">Raw points</span>
              </label>
            </div>
            <div class="w-full lg:w-2/12 flex flex-wrap">
              <div class="w-3/12 text-xxs text-secondary-700 mt-2 pl-2">Point radius</div>
              <input class="w-8/12 mt-2 text-xxs" type="range" min="1" max="100" step="1" v-model.number="circleRadius" @input="changeRadiusAllCircle()" />
              <div class="w-1/12 mt-2 text-xxs">{{ circleRadius }}</div>
            </div>
            <div class="w-full lg:w-2/12">
              <label class="inline-flex items-center mt-3">
                <input v-model="showPath" :value="showPath" type="checkbox" class="form-checkbox h-4 w-4" @change="showRawPath()" />
                <span class="ml-2 text-sm font-sans font-semibold">Raw Path</span>
              </label>
            </div>
            <div class="w-full lg:w-2/12">
              <label class="inline-flex items-center mt-3">
                <input v-model="showTrip" :value="showTrip" type="checkbox" class="form-checkbox h-4 w-4" @change="showTrackedTrip()" />
                <span class="ml-2 text-sm font-sans font-semibold">Show trips</span>
              </label>
            </div>
            <div class="w-full lg:w-3/12">
              <label class="inline-flex items-center mt-3">
                <input v-model="showAddress" :value="showAddress" type="checkbox" class="form-checkbox h-4 w-4" @change="showTrackedTrip()" />
                <span class="ml-2 text-sm font-sans font-semibold">Show start/end address</span>
              </label>
            </div>
            <div class="w-full lg:w-3/12">
              <label class="inline-flex items-center mt-3">
                <input v-model="fetchRouteResult" :value="showAddress" type="checkbox" class="form-checkbox h-4 w-4" @change="getRouteResult()" />
                <span class="ml-2 text-sm font-sans font-semibold">Route analyse</span>
              </label>
            </div>
          </div>
          <div class="w-full flex mt-4">
            <div ref="rightSidebar" class="lg:w-2/12 text-xs h-4/6 max-h-screen overflow-y-scroll relative">
              <div class="border bg-secondary-200 px-2 py-1 text-xxs sticky top-0 left-0 right-0">
                <div class="mt-1 pb-4 clear-both">
                  <div class="float-left">
                    <span class="btn-red text-xxs px-0.5 py-0.5 mr-2"><BaseIcon icon="project-diagram" class="cursor-pointer"/></span>
                    <span><BaseIcon icon="palette" class="cursor-pointer text-primary-500"/></span>
                  </div>
                  <div class="float-right">{{ formatDist(totalDist) }} km, {{ moment.duration(totalTripDuration, "seconds").humanize() }}</div>
                </div>
              </div>
              <div v-for="(v, i) in routePlayedItems" :key="i" class="cursor-pointer py-1">
                <div @click="focusRouteItem(v)" v-if="v.type == 'waiting'" class="bg-red-200 px-2 py-1" :class="i == routePlayedItems.length - 1 ? 'border-4 border-primary-500' : ''">
                  <div class="clear-both font-bold pb-4">
                    <div class="float-right text-gray-600" :class="v.duration > 1800 ? 'text-red-700' : ''">Stop {{ v.order }}</div>
                  </div>
                  <div class="font-bold" :class="v.duration > 1800 ? ' text-red-700' : ''">{{ v.address | formatAddress }}</div>
                  <div class="clear-both font-semibold">
                    <div class="float-right" :class="v.duration > 1800 ? 'text-red-700' : ''">{{ moment.duration(v.duration, "seconds").humanize() }}</div>
                  </div>
                  <div class="mt-1 pb-4 clear-both">
                    <div class="float-left"></div>
                    <div class="float-right" :class="v.duration > 1800 ? 'font-bold text-red-700' : ''">{{ moment(v.startTime).format("HH:mm:ss") }} - {{ moment(v.endTime).format("HH:mm:ss") }}</div>
                  </div>
                </div>
                <div @click="focusRouteItem(v)" v-if="v.type == 'trip'" :class="i == routePlayedItems.length - 1 ? 'border-4 border-primary-500' : 'border border-gray-100'">
                  <div class="clear-both font-bold pt-1 pb-5 px-2" :style="{ 'background-color': `${v.color}70` }" :class="i == routePlayedItems.length - 1 ? 'bg-primary-500' : ''">
                    <div class="float-left"><BaseIcon icon="caret-up" class="text-gray-800 font-bold text-lg" /></div>
                    <div class="float-right text-gray-900">Trip {{ v.order }}</div>
                  </div>
                  <div class="text-xxs text-gray-600 mt-1 px-2">{{ v.startAddress | formatAddress }}</div>
                  <div class="text-xxs text-gray-600 px-2"><BaseIcon icon="arrow-down" /></div>
                  <div class="text-xxs text-gray-600 px-2">{{ v.endAddress | formatAddress }}</div>
                  <div class="mt-1 px-2">
                    <span v-if="isPlaying && i == routePlayedItems.length - 1">{{ (v.currentDist / 1000).toFixed(2) }} / </span><span class="font-semibold ">{{ (v.distance / 1000).toFixed(2) }} km</span>
                  </div>
                  <div class="clear-both font-semibold px-2">
                    <div class="float-right">{{ moment.duration(v.duration, "seconds").humanize() }}</div>
                  </div>
                  <div class="mt-1 pb-4 clear-both px-2">
                    <div class="float-left"><BaseIcon icon="play-circle" /></div>
                    <div class="float-right">{{ moment(v.startTime).format("HH:mm:ss") }} - {{ moment(v.endTime).format("HH:mm:ss") }}</div>
                  </div>
                </div>
              </div>
              <div class="border bg-secondary-200 clear-both px-1 py-1 text-sm sticky bottom-0 left-0 right-0 flex">
                <div class="w-5/12">
                  <span @click="playAnimation()" class="cursor-pointer">
                    <BaseIcon v-if="isPlaying" icon="pause-circle" class="mr-1 text-blue-500" />
                    <BaseIcon v-else icon="play-circle" class="mr-1 text-blue-500" />
                  </span>
                  <span class="cursor-pointer"><BaseIcon icon="step-forward" class="mr-1"/></span>
                  <span @click="stopPlay = !stopPlay" class="cursor-pointer"><BaseIcon icon="stop-circle" class="mr-1 text-gray-400" :class="{ 'text-red-500': stopPlay }"/></span>
                </div>
                <div class="w-7/12 flex flex-wrap text-xs">
                  <input class="w-10/12 cursor-pointer" :style="{ direction: '' }" type="range" min="0" max="1000" step="100" v-model.number="playSpeed" />
                </div>
              </div>
            </div>
            <div class="lg:w-11/12">
              <GmapMap :center="center" :zoom="zoom" map-type-id="terrain" class="w-full h-screen" ref="map">
                <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false"></GmapInfoWindow>
                <GmapCircle v-for="(pin, index) in trackedPoints" :key="index + 'c'" :center="pin.position" :visible="true" :options="pin.options" @mouseover="showInfoWindow($event, pin.infoText, true)" @mouseout="showInfoWindow($event, pin.infoText, false)"></GmapCircle>
                <GmapPolyline :path="rawPath.path" :options="rawPath.options" @mouseover="showInfoWindow($event, rawPath.infoText, true)" @mouseout="showInfoWindow($event, rawPath.infoText, false)"></GmapPolyline>
                <GmapMarker :position="marker.position" :clickable="false" :draggable="false" :icon="marker.icon"></GmapMarker>
                <GmapMarker v-for="(m, index) in trackedPoints" :position="m.position" :clickable="false" :draggable="false" :key="index" @mouseover="showInfoWindow($event, m.infoText, true)" @mouseout="showInfoWindow($event, m.infoText, false)"></GmapMarker>
                <GmapPolyline v-for="(p, i) in trackedPaths" :key="i + 'p'" :path="p.path" :options="p.options" @mouseover="showInfoWindow($event, p.infoText, true, i)" @mouseout="showInfoWindow($event, p.infoText, false, i)"></GmapPolyline>
              </GmapMap>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/locale/pl";
import "vue2-datepicker/index.css";
import { gmapApi } from "vue2-google-maps";
import LineChart from "@/components/line_chart";

export default {
  name: "AlarmReport",
  title() {
    return `Larmrapport | SecurCloud`;
  },
  components: {
    DatePicker,
    LineChart,
  },
  data() {
    return {
      reportType: 2,
      imei: this.$route.params.imei,
      isLoading: false,
      date_picker_lang: this.$i18n.locale,
      date_picker_langKey: 0,
      date_range: [],
      columns: [
        { label: "Timestamp", field: "createdAt", formatFn: this.formatDate },
        { label: "Parsed", field: "parsed" },
        { label: "Rapportsträng", field: "gnss_string", sortable: false },
      ],
      reports: [],
      gpsData: [],
      alarm: null,
      lastSeen: null,
      lastGpsPos: null,
      voltageData: null,
      voltageOptions: null,
      // map
      zoom: this.$store.state.mapZoom,
      center: this.$store.state.mapCenter,
      showPoints: false,
      showPath: false,
      showTrip: false,
      showAddress: false,
      circleRadius: 80,
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: { content: "", pixelOffset: { width: 0, height: -35 } },
      trackedPoints: [],
      rawPath: {
        path: [],
        geodesic: true,
        options: {
          strokeColor: "red",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          icons: [],
        },
        infoText: "",
      },
      trackedPaths: [],
      fetchRouteResult: false,
      marker: { position: null, icon: null },
      isPlaying: false,
      stopPlay: false,
      playSpeed: 200,
      routeResults: [],
      routePlayedItems: [],
      totalDist: 0,
      totalTripDuration: 0,
    };
  },

  computed: {
    google: gmapApi,
    user() {
      return this.$store.state.user;
    },
  },

  filters: {
    formatAddress: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.substring(0, value.lastIndexOf(","));
    },
  },

  methods: {
    async getGPSReport() {
      try {
        if (this.fetchRouteResult) {
          this.getRouteResult();
          return;
        }

        this.isLoading = true;
        this.reports = [];
        this.gpsData = [];
        this.trackedPoints = [];
        this.rawPath.path = [];
        this.trackedPaths = [];
        this.showPoints = false;
        this.showPath = false;
        this.showTrip = false;
        this.showAddress = false;

        let start_date = new Date(this.moment(this.date_range[0]).startOf("day")).toISOString();
        let end_date = new Date(this.moment(this.date_range[1]).endOf("day")).toISOString();

        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/gps-report/${this.imei}/${start_date}/${end_date}`);
        this.reports = response.data.reports;
        this.alarm = response.data.alarm;

        this.isLoading = false;

        if (response.data.gps) {
          this.lastGpsPos = response.data.gps.gps_pos;
          this.lastSeen = this.getAlarmLastSeenTime(response.data.gps.updatedAt);
          this.focusCurrentPosition();
        } else {
          this.lastGpsPos = null;
          this.lastSeen = null;
        }

        this.setPageTitle(`Larmrapport - IMEI: ${this.imei}`, "alarm_report");

        this.parseGpsData();
        this.drawVoltageGraph();
      } catch (error) {
        this.isLoading = false;
        this.handleError(error);
      }
    },

    initDateRange() {
      const start = new Date(this.moment().startOf("day"));
      const end = new Date(this.moment().endOf("day"));
      // const start = new Date(this.moment().subtract(6, 'day').startOf("day"));
      // const end = new Date(this.moment().subtract(6, 'day').endOf("day"));

      this.date_range = [start, end];
    },

    disableDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },

    selectToday(emit) {
      const start = new Date(this.moment().startOf("day"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },

    selectCurrentWeek(emit) {
      const start = new Date(this.moment().startOf("isoWeek"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },

    selectLastWeek(emit) {
      const start = new Date(
        this.moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
      );
      const end = new Date(
        this.moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
      );
      const date = [start, end];
      emit(date);
    },

    selectLast2Week(emit) {
      const start = new Date(
        this.moment()
          .subtract(2, "weeks")
          .startOf("isoWeek")
      );
      const end = new Date(
        this.moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
      );
      const date = [start, end];
      emit(date);
    },

    exportData() {
      const newColumns = this.reports.map((item) => ({
        IMEI: item.imei_number,
        Timestamp: this.formatDate(item.createdAt),
        Report: item.gnss_string,
      }));

      this.excelExport(newColumns, "larms", "larm_rapport");
    },

    getAlarmLastSeenTime(updatedAt) {
      return {
        value: this.moment(updatedAt).fromNow(),
        time: this.moment().diff(this.moment(updatedAt)),
      };
    },

    formatDate(date) {
      return this.moment(date)
        .tz("Europe/Stockholm")
        .format("ddd, YYYY-MM-DD, HH:mm:ss");
    },

    // graph

    drawVoltageGraph() {
      let voltages = [],
        labels = [],
        chargerEvents = [];

      for (let i = this.gpsData.length - 1; i >= 0; i--) {
        let report = this.gpsData[i];
        voltages.push(report.battery);
        labels.push(this.moment(report.createdAt).format("MM-DD, HH:mm"));

        if (report.type == "charge_on" || report.type == "charge_off") {
          chargerEvents.push({
            type: "line",
            mode: "vertical",
            scaleID: "x-axis-0",
            value: this.moment(report.createdAt).format("MM-DD, HH:mm"),
            borderColor: report.type == "charge_on" ? "red" : "green",
            borderWidth: 1,
          });
        }
      }

      this.voltageOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            min: 4.4,
            max: 3.2,
          },
        },
        annotation: {
          drawTime: "afterDatasetsDraw",
          annotations: chargerEvents,
        },
      };

      this.voltageData = {
        labels: labels,
        datasets: [
          {
            label: "Voltage",
            data: voltages,
            backgroundColor: "#1A8DE9",
            borderColor: "#1A8DE9",
            borderWidth: 3,
            pointRadius: 0,
            fill: false,
          },
        ],
      };
    },

    // parse report

    parseGpsData() {
      this.gpsData = _.map(this.reports, "parsed");
      // this.playAnimation()
    },

    // map

    showTrackedPoints() {
      this.trackedPoints = [];

      if (this.showPoints) {
        for (let i = 0; i < this.gpsData.length; i++) {
          let point = this.gpsData[i];
          if (point.lat) {
            this.trackedPoints.push({
              position: { lat: point.lat, lng: point.lng },
              speed: point.speed,
              type: "valid",
              options: {
                fillColor: "#1A8DE9",
                fillOpacity: 0.5,
                strokeColor: "#1A8DE9",
                strokeOpacity: 0.8,
                strokeWeight: 1,
                radius: this.circleRadius,
              },
              infoText: `<b>Time:</b> ${this.moment(point.createdAt)
                .tz("Europe/Stockholm")
                .format("MM-DD, HH:mm:ss")}<br>
                        <b>Speed:</b> ${point.speed} km/hr
                        <b>Lat:</b> ${point.lat}, <b>Lng:</b> ${point.lng}<br>`,
            });
          }
        }
      }
    },

    showRawPath() {
      this.rawPath.path = [];
      this.rawPath.infoText = ``;
      let rawPath = [];

      if (this.showPath) {
        for (let i = this.gpsData.length - 1; i >= 0; i--) {
          let point = this.gpsData[i];
          rawPath.push({
            lat: point.lat,
            lng: point.lng,
          });
        }

        let distance_travelled = 0;
        for (let i = 0; i < rawPath.length - 1; i++) {
          let pos1 = rawPath[i];
          let pos2 = rawPath[i + 1];

          distance_travelled += this.getDistanceFromLatLonInM(pos1, pos2);
        }

        if (distance_travelled > 0) distance_travelled = (distance_travelled / 1000).toFixed(2);

        this.rawPath.path = rawPath;
        this.rawPath.options.icons = [{ icon: { path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW, scale: 2 }, offset: "100%", repeat: "200px" }];
        this.rawPath.infoText = `Distance: ${distance_travelled} km`;
      }
    },

    async showTrackedTrip() {
      this.trackedPaths = [];
      let paths = [],
        path = { path: [], startTime: null, endTime: null };

      let tripStart = false;
      let higher_start_speed = 10;
      let lower_start_speed = 4;
      let max_stop_speed = 5;

      if (this.showTrip) {
        for (let i = this.gpsData.length - 1; i >= 0; i--) {
          let point = this.gpsData[i];

          if (tripStart) {
            path.path.push({
              lat: point.lat,
              lng: point.lng,
            });
            // todo: show bearning curve
            // let p = this.calculateNextBearningPoint(point.lat, point.lng, 1.5, point.heading);
            // path.path.push({
            //   lat: p[0],
            //   lng: p[1],
            // });
          }

          // start
          if (point.speed > higher_start_speed && tripStart == false) {
            if (i + 3 <= this.gpsData.length - 1) {
              let p1 = this.gpsData[i + 1];
              let p2 = this.gpsData[i + 2];
              let p3 = this.gpsData[i + 3];

              let isMoving = p1.speed > lower_start_speed || p2.speed > lower_start_speed || p3.speed > lower_start_speed;
              // let isMoving = p1.speed > lower_start_speed && p2.speed > lower_start_speed && p3.speed > lower_start_speed;

              if (isMoving) {
                tripStart = true;
                path = {
                  startTime: this.gpsData[i + 3].createdAt,
                  endTime: null,
                  path: [
                    { lat: p3.lat, lng: p3.lng },
                    { lat: p2.lat, lng: p2.lng },
                    { lat: p1.lat, lng: p1.lng },
                    { lat: point.lat, lng: point.lng },
                  ],
                };
              }
            }
          }

          // stop
          if (tripStart == true && point.speed < 4) {
            if (i + 3 <= this.gpsData.length - 1) {
              let p1 = this.gpsData[i + 1];
              let p2 = this.gpsData[i + 2];
              let p3 = this.gpsData[i + 3];
              if (p1.speed < max_stop_speed && p2.speed < max_stop_speed && p3.speed < max_stop_speed) {
                tripStart = false;
                path.path.splice(path.path.length - 3, 3);
                path.endTime = p3.createdAt;
                paths.push(path);
                path = { path: [], startTime: null, endTime: null };
              }
            }
          }
          // stop if end point and still moving
          if (tripStart == true && i == 0) {
            paths.push(path);
          }
        }

        for (let i = 0; i < paths.length; i++) {
          let path = paths[i];

          let tripEndTime =
            path.endTime != null
              ? this.moment(path.endTime)
                  .tz("Europe/Stockholm")
                  .format("MM-DD, HH:mm:ss")
              : "-";

          let distance_travelled = 0;
          for (let i = 0; i < path.path.length - 1; i++) {
            let pos1 = path.path[i];
            let pos2 = path.path[i + 1];

            distance_travelled += this.getDistanceFromLatLonInM(pos1, pos2);
          }

          if (distance_travelled > 0) distance_travelled = (distance_travelled / 1000).toFixed(2);

          if (distance_travelled > 0.5) {
            // 500m
            let startLocation = "-",
              endLocation = "-";

            let tripPath = path.path;

            if (this.showAddress) {
              try {
                const geocoder = new this.google.maps.Geocoder();
                await delay(1000);
                let res = await geocoder.geocode({ location: path.path[0] });
                startLocation = res.results[0].formatted_address;

                if (path.endTime != null) {
                  await delay(1000);
                  let rese = await geocoder.geocode({ location: path.path[path.path.length - 1] });
                  endLocation = rese.results[0].formatted_address;
                }
              } catch (error) {
                console.log(error);
              }
            }

            this.trackedPaths.push({
              path: tripPath,
              geodesic: true,
              options: {
                strokeColor: this.getRandomPathColor(),
                strokeOpacity: 1,
                strokeWeight: 3,
                icons: [{ icon: { path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW, scale: 2 }, offset: "100%", repeat: "200px" }],
              },
              infoText: `Start time: ${this.moment(path.startTime)
                .tz("Europe/Stockholm")
                .format("MM-DD, HH:mm:ss")}<br>
                        End time: ${tripEndTime}<br>
                        Start location: ${startLocation}<br>
                        End location: ${endLocation}<br>
                        Distance: ${distance_travelled} km`,
            });
          }
        }
      }

      function delay(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
      }
    },

    async getRouteResult() {
      try {
        this.routeResults = [];
        this.routePlayedItems = [];
        this.trackedPoints = [];
        this.trackedPaths = [];

        let start = new Date(this.moment(this.date_range[0]).startOf("day")).toISOString();
        let end = null;
        // let end = new Date(this.moment(this.date_range[1]).endOf("day")).toISOString();

        this.isLoading = true;

        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/route-analytics/${this.imei}/${start}/${end}`);
        this.routeResults = response.data.routeResults;
        this.routePlayedItems = this.routeResults;
        this.alarm = response.data.alarm;

        this.isLoading = false;

        this.reverseGeocodeAddresses();
      } catch (error) {
        this.isLoading = false;
        this.handleError(error);
      }
    },

    async reverseGeocodeAddresses() {
      this.routeResults.forEach(async (r) => {
        // todo: use same address for lastWaitingStop & tripStartAddress, tripEndAdress & waitingStop
        if (r.type == "trip") {
          let startAddress = await this.reverseGeocode(r.startPosition);
          let endAddress = await this.reverseGeocode(r.endPosition);

          r.startAddress = startAddress;
          r.endAddress = endAddress;
        } else if (r.type == "waiting") {
          let address = await this.reverseGeocode(r.position);
          r.address = address;
        }
      });
    },

    async reverseGeocode(point) {
      var vm = this;
      let address = "";

      if (!point) return address;

      try {
        const geocoder = new vm.google.maps.Geocoder();
        let res = await geocoder.geocode({ location: point });
        address = res.results[0].formatted_address;
      } catch (error) {
        console.log(error);
      }

      return address;
    },

    async playAnimation() {
      // if(this.routeResults.length == 0) await this.processRoute();
      this.isPlaying = !this.isPlaying;
      this.playRouteSequence();
    },

    // generate trips
    /*
    async processRoute() {
      var vm = this;

      let MIN_SPEED = 0; // km/hr, 3 for non-vehicle devices
      if(this.alarm && /^SRT/g.test(this.alarm.type)) MIN_SPEED = 7;

      let tripStartTime = null, waitingStartTime = null, 
      tripCount = 0, waitingStopCount = 0;

      let route = _.cloneDeep(this.gpsData).reverse(); // increasing time seq.
      this.routeResults = [];
      let routeResults = [];

      this.isLoading = true;

      for (let i = 0; i < route.length; i++) {
        let point = route[i];

        let waitingDuration = 0, tripDuration = 0;

        let currentPointTime = this.moment(point.createdAt);

        console.log(`%c${currentPointTime.format("HH:mm:ss")} ${point.speed} km/hr`, `color: ${point.speed > MIN_SPEED ? 'green' : 'red'};`);

        // either it's moving or stopped
        if(point.speed <= MIN_SPEED) {
          // check if vehicle have stopped more than threshold
          
          let isCurrentlyWaiting = _.findIndex(routeResults, { startTime: waitingStartTime ? waitingStartTime.toISOString() : null, type: 'waiting' });

          if(isCurrentlyWaiting > -1) {
            waitingDuration = currentPointTime.diff(waitingStartTime, 'seconds');

            routeResults[isCurrentlyWaiting].endTime = point.createdAt; // iso timestamp
            routeResults[isCurrentlyWaiting].duration = waitingDuration; // second
          }
          else {
            // when found waiting for more than threshold then add new waiting stop
            // change waiting duration of current stop if found waiting more
            
            // only last 100 points
            let lastPointsStartIndex = 0;
            if(i > 101) lastPointsStartIndex = i - 100;
            let lastPoints = route.slice(lastPointsStartIndex, i);
            let lastMovingPoint = _.findLast(lastPoints, (o) => { return o.speed > MIN_SPEED; });

            if(lastMovingPoint) {
              waitingStartTime = this.moment(lastMovingPoint.createdAt);
              waitingDuration = currentPointTime.diff(waitingStartTime, 'seconds');
            }

            if(waitingDuration > 90) { // 1.5 minute
              // waitingStopCount++;

              let waitingAddress = '';
              try {
                const geocoder = new vm.google.maps.Geocoder();
                // todo: add delay if api limit is hit
                // await delay(100);
                let res = await geocoder.geocode({ location: { lat: point.lat, lng: point.lng } });
                waitingAddress = res.results[0].formatted_address;
              } catch (error) {
                console.log(error);
              }

              // stop and save current trip
              let currentTrip = _.findIndex(routeResults, { startTime: tripStartTime ? tripStartTime.toISOString() : null, type: 'trip' });
              if(currentTrip > -1) {
                // complete ongoing trip
                routeResults[currentTrip].endAddress = waitingAddress;
                tripStartTime = null;
              }

              routeResults.push({
                type: 'waiting',
                position: { lat: point.lat, lng: point.lng },
                startTime: waitingStartTime.toISOString(),
                endTime: point.createdAt,
                duration: waitingDuration,
                address: waitingAddress,
                order: 0,
              });
            }
          }
        }
        else {
          // calculate trip
          // check if trip is ongoing
          let currentTrip = _.findIndex(routeResults, { startTime: tripStartTime ? tripStartTime.toISOString() : null, type: 'trip' });
          if(currentTrip > -1) {
            let prevPoint = i-1 > 0 ? routeResults[currentTrip].path[routeResults[currentTrip].path.length - 1] : -1;
            let distPrevPoint = prevPoint ? this.getDistanceFromLatLonInM({ lat: point.lat, lng: point.lng }, prevPoint) : 0;
            let distTotal = routeResults[currentTrip].distance;

            tripDuration = currentPointTime.diff(tripStartTime, 'seconds');
            
            routeResults[currentTrip].endTime = point.createdAt;
            routeResults[currentTrip].duration = tripDuration;
            routeResults[currentTrip].distance += distPrevPoint ? distPrevPoint : 0;
            routeResults[currentTrip].path.push({ lat: point.lat, lng: point.lng, time: point.createdAt, speed: point.speed, dist: distTotal });
            
          }
          else {
            // add new trip
            // todo: don't create new trip if it's gps jump
            // tripCount++;

            tripStartTime = this.moment(point.createdAt);

            let startAddress = '';
            try {
              const geocoder = new vm.google.maps.Geocoder();
              let res = await geocoder.geocode({ location: { lat: point.lat, lng: point.lng } });
              startAddress = res.results[0].formatted_address;
            } catch (error) {
              console.log(error);
            }

            // reset ongoing waiting
            waitingStartTime = null;

            routeResults.push({
              type: 'trip',
              path: [{ lat: point.lat, lng: point.lng, time: point.createdAt, speed: point.speed, dist: 0 }],
              startTime: point.createdAt,
              endTime: point.createdAt,
              duration: tripDuration,
              distance: 0,
              startAddress,
              endAddress: '',
              order: 0,
            });
          }
        }
      }

      // patch remove trips with no dist
      let routeResultsCleaned = [];

      for (let i = 0; i < routeResults.length; i++) {
        let r = routeResults[i];

        if(r.type == 'trip') {
          if(r.distance <= 100) {
            // merge previous waiting stop in next
            if(i+1 < routeResults.length && routeResults[i+1].type == 'waiting') {
              // find last active waiting point
              let lastRouteItem = routeResultsCleaned[routeResultsCleaned.length-1];
              if(lastRouteItem && lastRouteItem.type == 'waiting') {
                // set time of last waiting stop in prev
                lastRouteItem.endTime = routeResults[i+1].endTime;
                
                let duration = this.moment(lastRouteItem.endTime).diff(this.moment(lastRouteItem.startTime), 'seconds');
                lastRouteItem.duration = duration;

                routeResults[i+1].endTime = null;
              }
            }

            continue;
          }
          else {
            tripCount++;
            r.order = tripCount;

            routeResultsCleaned.push(r);
          }
        }
        else if(r.type == 'waiting' && r.endTime != null) {
          waitingStopCount++;
          r.order = waitingStopCount;

          routeResultsCleaned.push(r);
        }
      } 

      this.routeResults = routeResultsCleaned;

      this.isLoading = false;

      console.log(routeResults);
    },
    */

    // play trip seq.
    async playRouteSequence() {
      var vm = this;

      this.trackedPoints = []; // waiting stops
      this.trackedPaths = []; // trips
      this.routePlayedItems = [];

      this.totalDist = 0; // total distance for selected period
      this.totalTripDuration = 0;

      this.rawPath.options.icons = [{ icon: { path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW, scale: 2 }, offset: "100%", repeat: "700px" }];
      vm.$refs.map.$mapObject.setZoom(13);
      this.marker = { position: null, icon: { path: this.google.maps.SymbolPath.CIRCLE, scale: 4 } };

      for (let i = 0; i < this.routeResults.length; i++) {
        let r = this.routeResults[i];

        this.routePlayedItems.push(r);
        this.scrollToBottom();

        let infoText = "";
        r.currentDist = 0;

        if (r.type == "trip") {
          this.trackedPaths.push({
            path: [],
            geodesic: true,
            options: {
              strokeColor: "#1A8DE9",
              strokeOpacity: 1,
              strokeWeight: 4,
              zIndex: 9999,
              icons: [{ icon: { path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW, scale: 2 }, offset: "100%", repeat: "700px" }],
            },
            infoText: infoText,
          });

          for (let j = 0; j < r.path.length; j++) {
            let point = r.path[j];

            infoText = `<b>Trip ${r.order}</b><br>
            ${vm.formatAdd(r.startAddress)} -> ${vm.formatAdd(r.endAddress)}<br>
            ${this.moment(r.startTime).format("HH:mm")} -> ${this.moment(r.endTime).format("HH:mm")}, ${vm.moment.duration(r.duration, "seconds").humanize()}, ${vm.formatDist(r.distance)} km<br>
            <b>${vm.moment(point.time).format("HH:mm:ss")}, ${vm.formatDist(point.dist)} km, ${this.formatSpeed(point.speed)} km/hr</b>
            `;

            if (!this.stopPlay) await delay(this.playSpeed);
            this.trackedPaths[this.trackedPaths.length - 1].path.push(point);

            vm.$refs.map.$mapObject.panTo(point);
            vm.marker.position = point;
            vm.showInfoWindow({ latLng: point }, infoText, true);

            r.currentDist = point.dist;
          }

          // update trip info after all points are played
          let color = this.getRandomPathColor();

          this.trackedPaths[this.trackedPaths.length - 1].options.strokeColor = color;
          this.trackedPaths[this.trackedPaths.length - 1].options.strokeOpacity = 1;
          this.trackedPaths[this.trackedPaths.length - 1].options.strokeWeight = 2.5;
          this.trackedPaths[this.trackedPaths.length - 1].options.zIndex = r.order;

          infoText = `<b>Trip ${r.order}</b><br>
          ${vm.formatAdd(r.startAddress)} -> ${vm.formatAdd(r.endAddress)}<br>
          ${this.moment(r.startTime).format("HH:mm:ss")} -> ${this.moment(r.endTime).format("HH:mm:ss")}, ${vm.moment.duration(r.duration, "seconds").humanize()}<br>
          ${vm.formatDist(r.distance)} km<br>
          `;
          this.trackedPaths[this.trackedPaths.length - 1].infoText = infoText;

          r.infoText = infoText;
          r.position = r.startPosition;
          r.color = color;

          this.totalDist += r.distance;
          this.totalTripDuration += r.duration;
        } else if (r.type == "waiting") {
          infoText = `<b>Stop ${r.order}</b><br>
          <b>${vm.formatAdd(r.address)}</b><br>
          ${this.moment(r.startTime).format("HH:mm")} - ${this.moment(r.endTime).format("HH:mm")}, ${vm.moment.duration(r.duration, "seconds").humanize()}
          `;

          vm.trackedPoints.push({
            ...r,
            options: {
              fillColor: "red",
              fillOpacity: 0,
              strokeColor: "red",
              strokeOpacity: 1,
              strokeWeight: 4,
              radius: vm.circleRadius, // 80 by default
            },
            infoText: infoText,
          });

          vm.$refs.map.$mapObject.panTo(r.position);
          vm.showInfoWindow({ latLng: r.position }, infoText, true);
          r.infoText = infoText;

          if (!this.stopPlay) await delay(this.playSpeed * 10);
        }
      }

      this.isPlaying = false;

      function delay(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
      }
    },

    formatAdd: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.substring(0, value.indexOf(","));
    },

    formatDist(value) {
      return (value / 1000).toFixed(2);
    },

    formatSpeed(value) {
      return Math.ceil(value);
    },

    scrollToBottom() {
      this.$nextTick(function() {
        let el = this.$refs.rightSidebar;
        el.scrollTop = el.scrollHeight;
      });
    },

    calculateNextBearningPoint(lat, lon, distance, bearing) {
      var radius = 6371e3; // (Mean) radius of earth

      var toRadians = function(v) {
        return (v * Math.PI) / 180;
      };
      var toDegrees = function(v) {
        return (v * 180) / Math.PI;
      };

      var δ = Number(distance) / radius; // angular distance in radians
      var θ = toRadians(Number(bearing));

      var φ1 = toRadians(Number(lat));
      var λ1 = toRadians(Number(lon));

      var sinφ1 = Math.sin(φ1),
        cosφ1 = Math.cos(φ1);
      var sinδ = Math.sin(δ),
        cosδ = Math.cos(δ);
      var sinθ = Math.sin(θ),
        cosθ = Math.cos(θ);

      var sinφ2 = sinφ1 * cosδ + cosφ1 * sinδ * cosθ;
      var φ2 = Math.asin(sinφ2);
      var y = sinθ * sinδ * cosφ1;
      var x = cosδ - sinφ1 * sinφ2;
      var λ2 = λ1 + Math.atan2(y, x);

      return [toDegrees(φ2), ((toDegrees(λ2) + 540) % 360) - 180]; // normalise to −180..+180°
    },

    getRandomPathColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    getDistanceFromLatLonInM(pos1, pos2) {
      var R = 6378100; // Radius of the earth in m
      var dLat = this.deg2rad(pos2.lat - pos1.lat);
      var dLon = this.deg2rad(pos2.lng - pos2.lng);
      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(pos1.lat)) * Math.cos(this.deg2rad(pos2.lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in m
      return d;
    },

    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },

    changeRadiusAllCircle() {
      this.trackedPoints.forEach((p) => {
        p.options.radius = this.circleRadius;
      });
    },

    showInfoWindow(event, infoText, show, index = -1) {
      if (show) {
        // set
        this.infoWindowPos = event.latLng;
        this.infoOptions.content = infoText;
        this.infoWinOpen = show;
        if (index > -1) {
          this.trackedPaths[index].options.strokeWeight = 4;
          this.trackedPaths[index].options.strokeOpacity = 1;

          for (let i = 0; i < this.trackedPaths.length; i++) {
            let p = this.trackedPaths[i];
            if (i != index) {
              p.options.strokeOpacity = 0.2;
              p.options.strokeWeight = 2.5;
            }
          }
        }
      } else {
        // reset
        this.infoWinOpen = show;
        for (let i = 0; i < this.trackedPaths.length; i++) {
          let p = this.trackedPaths[i];
          p.options.strokeOpacity = 1;
          p.options.strokeWeight = 2.5;
        }
      }
    },

    focusCurrentPosition(position = null) {
      var vm = this;
      let point = position ? position : this.lastGpsPos;

      if (point) {
        setTimeout(() => {
          vm.$refs.map.$mapObject.panTo(point);
          vm.$refs.map.$mapObject.setZoom(15);
        }, 300);
      }
    },

    focusRouteItem(point) {
      var vm = this;

      if (point && point.position) {
        setTimeout(() => {
          vm.$refs.map.$mapObject.panTo(point.position);
          vm.$refs.map.$mapObject.setZoom(13);

          if (point.type == "trip") {
            vm.showInfoWindow({ latLng: point.position }, point.infoText, true, point.order - 1);
          } else {
            vm.showInfoWindow({ latLng: point.position }, point.infoText, true);
            for (let i = 0; i < this.trackedPaths.length; i++) {
              let p = this.trackedPaths[i];
              p.options.strokeOpacity = 1;
              p.options.strokeWeight = 2.5;
            }
          }
        }, 300);
      }
    },
  },

  watch: {
    "$i18n.locale"(newLocale) {
      this.date_picker_lang = newLocale;
      this.date_picker_langKey++;
    },
  },

  created() {
    this.isLoading = true;
    this.initDateRange();
    this.getGPSReport();
  },
};
</script>
